var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "form-div" }, [
      _c("form", { on: { submit: _vm.handleLogin } }, [
        _c("div", { staticClass: "form-grouped" }, [
          _c("div", { staticClass: "div-label" }, [
            _vm._v("\n          Email address\n\n          "),
            _vm.form_errors.main
              ? _c("span", { staticClass: "form_errors" }, [
                  _vm._v(_vm._s(_vm.form_errors.main)),
                ])
              : _vm._e(),
          ]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.email,
                expression: "form.email",
              },
            ],
            staticClass: "form-control",
            attrs: {
              name: "email",
              placeholder: "enter email address",
              type: "email",
            },
            domProps: { value: _vm.form.email },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.form, "email", $event.target.value)
              },
            },
          }),
        ]),
        _c("div", { staticClass: "form-grouped" }, [
          _vm._m(0),
          _c("div", { staticClass: "pass-field" }, [
            (!_vm.ispwdVisible ? "password" : "text") === "checkbox"
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.password,
                      expression: "form.password",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    name: "password",
                    placeholder: "enter password",
                    type: "checkbox",
                  },
                  domProps: {
                    checked: Array.isArray(_vm.form.password)
                      ? _vm._i(_vm.form.password, null) > -1
                      : _vm.form.password,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.form.password,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(_vm.form, "password", $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.form,
                              "password",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.form, "password", $$c)
                      }
                    },
                  },
                })
              : (!_vm.ispwdVisible ? "password" : "text") === "radio"
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.password,
                      expression: "form.password",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    name: "password",
                    placeholder: "enter password",
                    type: "radio",
                  },
                  domProps: { checked: _vm._q(_vm.form.password, null) },
                  on: {
                    change: function ($event) {
                      return _vm.$set(_vm.form, "password", null)
                    },
                  },
                })
              : _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.password,
                      expression: "form.password",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    name: "password",
                    placeholder: "enter password",
                    type: !_vm.ispwdVisible ? "password" : "text",
                  },
                  domProps: { value: _vm.form.password },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.form, "password", $event.target.value)
                    },
                  },
                }),
            _c("img", {
              staticStyle: { width: "5%" },
              attrs: {
                src: !_vm.ispwdVisible
                  ? "/img/onboarding/eye_close.svg"
                  : "/img/onboarding/eye_open.svg",
                alt: "eye",
              },
              on: {
                click: function () {
                  return (_vm.ispwdVisible = !_vm.ispwdVisible)
                },
              },
            }),
          ]),
        ]),
        _c("div", { staticClass: "action-btn" }, [
          _c(
            "button",
            {
              staticClass: "grupa-blue-btn",
              attrs: { type: "submit", disabled: _vm.spinner },
            },
            [
              _vm._v("\n          Proceed to Dashboard\n          "),
              _c("img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.spinner,
                    expression: "spinner",
                  },
                ],
                staticClass: "button-spinner",
                staticStyle: { "margin-left": "5px" },
                attrs: { src: "/img/lightbox/preloader.gif" },
              }),
            ]
          ),
        ]),
        _c("div", { staticClass: "register-div" }, [
          _c(
            "div",
            { staticClass: "sign-link", on: { click: _vm.handleRegister } },
            [
              _vm._v("\n          First time here? "),
              _c("span", [_vm._v("Signup")]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "div-label password-label" }, [
      _c("div", [_vm._v("Password")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }