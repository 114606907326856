<template>
  <div>
    <div class="form-div">
      <form @submit="handleLogin">
        <div class="form-grouped">
          <div class="div-label">
            Email address

            <span class="form_errors" v-if="form_errors.main">{{
              form_errors.main
            }}</span>
          </div>
          <input
            class="form-control"
            name="email"
            v-model="form.email"
            placeholder="enter email address"
            type="email"
          />
        </div>
        <div class="form-grouped">
          <div class="div-label password-label">
            <div>Password</div>
            <!-- <div class="forgot-password" @click="handleForgotPassword">
              Forgot Password?
            </div> -->
          </div>
          <div class="pass-field">
            <input
              class="form-control"
              name="password"
              v-model="form.password"
              placeholder="enter password"
              :type="!ispwdVisible ? 'password' : 'text'"
            />
            <img
              :src="
                !ispwdVisible
                  ? '/img/onboarding/eye_close.svg'
                  : '/img/onboarding/eye_open.svg'
              "
              alt="eye"
              style="width: 5%"
              @click="() => (ispwdVisible = !ispwdVisible)"
            />
          </div>
        </div>
        <div class="action-btn">
          <button type="submit" class="grupa-blue-btn" :disabled="spinner">
            Proceed to Dashboard
            <img
              src="/img/lightbox/preloader.gif"
              class="button-spinner"
              v-show="spinner"
              style="margin-left: 5px"
            />
          </button>
        </div>
        <div class="register-div">
          <div class="sign-link" @click="handleRegister">
            First time here? <span>Signup</span>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    form: {
      type: Object,
    },
    form_errors: {
      type: Object,
    },
    handleLogin: {
      type: Function,
    },
    spinner: {
      type: false,
    },
    handleRegister: {
      type: Function,
    },
  },
  components: {},

  data: () => ({
    ispwdVisible: false,
  }),

  created() {},
  methods: {
    handleForgotPassword() {
      this.$router.push({ name: "forgot_password" });
    },
  },
  computed: {},
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.form-div {
}
.form-grouped {
  margin-top: 48px;
}
.div-label {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  text-align: left;
}
.form-control {
  margin-top: 4px;
  margin-bottom: 0px;
}
.register-div,
.action-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 48px;
}
.register-div {
  margin-top: 16px;
}
.sign-link {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  cursor: pointer;
}
.sign-link span {
  color: #0781f2;
}
.form_errors {
  float: right;
  color: red;
  font-size: 12px;
}
.pass-field {
  position: relative;
  height: 50px;
}
.pass-field img {
  position: absolute;
  top: 30%;
  right: 12px;
  cursor: pointer;
}
.password-label {
  display: flex;
  justify-content: space-between;
}
.forgot-password {
  font-size: 14px;
  line-height: 130%;
  color: #0781f2;
  cursor: pointer;
}
</style>
