var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.user
        ? _c("div", { staticClass: "loader-container" }, [
            _c(
              "div",
              { staticClass: "loading-wrapper" },
              [
                _c("v-progress-linear", {
                  attrs: {
                    value: "3",
                    height: "3",
                    indeterminate: "",
                    color: "black",
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.user == false
        ? _c(
            "div",
            [
              _c("Navbar"),
              _c("div", { staticClass: "login-container" }, [
                _c(
                  "div",
                  { staticClass: "page-wrapper" },
                  [
                    _c("div", { staticClass: "welcome-text" }, [
                      _vm._v("Welcome back,"),
                    ]),
                    _c("div", { staticClass: "grey-text" }, [
                      _vm._v("Kindly fill in your details"),
                    ]),
                    _c(
                      "LoginForm",
                      _vm._b(
                        {},
                        "LoginForm",
                        {
                          form: _vm.form,
                          handleLogin: _vm.handleLogin,
                          form_errors: _vm.form_errors,
                          spinner: _vm.spinner,
                          handleRegister: _vm.handleRegister,
                        },
                        false
                      )
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.successMsg ? _c("AlertSuccess", { attrs: { time: "7" } }) : _vm._e(),
      _vm.errorMsg ? _c("AlertError", { attrs: { time: "7" } }) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }